@keyframes roundBabe {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes roundBabeAgain {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 45px;
  padding-right: 45px;
  width: 292px;
  height: 50px;
  background-color: var(--color-white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid var(--color-white);
  color: var(--color-gray);
  font-weight: var(--font-primary-bold);
  font-size: var(--font-size-md);
  text-align: center;
  text-decoration: none;
  outline-style: none;
  cursor: pointer;

  &__clicked {
    animation-duration: 1s;
    animation-name: roundBabe;

    &Again {
      animation-duration: 1s;
      animation-name: roundBabeAgain;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}
