@keyframes slideIn {
  from {
    transform: scale(0.7);
  }

  to {
    transform: scale(1);
  }
}

.term {
  max-width: 1035px;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 0.5s;
  animation-name: slideIn;

  &__button {
    background-color: transparent;
    height: 40px;
    border: none;

    &:hover {
      opacity: 0.8;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 600px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  &__bird {
    position: relative;
    left: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      left: 40px;
    }
  }
}
