@keyframes slideIn {
  from {
    transform: scale(0.7);
  }

  to {
    transform: scale(1);
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 0.5s;
  animation-name: slideIn;

  &__arrow {
    margin-top: 10px;
    width: 48px;

    @media screen and (min-width: 1040px) {
      transform: rotate(-90deg);
      margin-bottom: 100px;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 1040px) {
      flex-direction: row;
    }

    &Photo {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      border: 3px solid #ffffff;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &__boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 210px;
    min-width: 300px;

    @media screen and (min-width: 1040px) {
      min-height: 240px;
    }
  }

  &__icon {
    margin-bottom: 0px;
  }

  &__paragraph {
    font-weight: var(--font-primary-regular);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    text-align: center;
    margin-bottom: 10px;

    &Box {
      @extend .confirmation__paragraph;
      width: 245px;

      &Width {
        @extend .confirmation__paragraphBox;
        width: 290px;
      }
    }

    &Bold {
      @extend .confirmation__paragraph;
      font-weight: var(--font-primary-bold);

      &Margin {
        @extend .confirmation__paragraphBold;
        margin-top: 20px;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  max-width: 300px;
  height: 50px;
  margin-top: 29px;
}
