@keyframes slideIn {
  from {
    transform: scale(0.7);
  }

  to {
    transform: scale(1);
  }
}

.rent {
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 0.5s;
  animation-name: slideIn;

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 60px;

    @media screen and (min-width: 1040px) {
      flex-direction: row;
      margin-top: 65px;
      margin-bottom: 167px;
    }

    &Photo {
      width: 135px;
      height: 135px;
      border-radius: 50%;
      border: 5px solid #ffffff;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
      margin-bottom: 20px;

      @media screen and (min-width: 1040px) {
        margin-bottom: 0;
      }
    }

    &Qr {
      width: 0;
      height: 0;

      @media screen and (min-width: 1040px) {
        width: 135px;
        height: 135px;
      }
    }
  }

  &__paragraph {
    font-weight: var(--font-primary-regular);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    text-align: center;
    margin: 20px auto 0 auto;
    max-width: 590px;

    &Bold {
      @extend .rent__paragraph;

      font-weight: var(--font-primary-bold);
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 10px;

    &Bold {
      font-weight: var(--font-primary-bold);
    }

    &Decoration {
      text-decoration: underline;
      font-weight: var(--font-primary-bold);
    }
  }

  &__title {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
    text-align: center;
    margin-bottom: 10px;
  }

  &__email {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--color-white);
    box-shadow: 0px 1.38365px 5.53459px rgba(0, 0, 0, 0.25);
    margin-right: 20px;
  }

  &__phoneIcon {
    margin-right: 20px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 600px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  &__arrowLeft {
    position: relative;
    right: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      right: 40px;
    }
  }

  &__arrowRight {
    position: relative;
    left: 20px;
    height: 16px;

    @media screen and (min-width: 730px) {
      left: 40px;
    }
  }
}
