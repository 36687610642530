.footer {
  background: #1d1c1c;

  &__copyright {
    color: #979797;
    font-weight: var(--font-primary-regular);
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
    text-decoration-line: underline;
    height: 3.1rem;
  }

  &__title {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-mx);
    line-height: var(--line-height-mx);
    margin-bottom: 0.5rem;
  }

  &__paragraph {
    width: 220px;
    font-weight: var(--font-primary-regular);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
  }

  &__wrapper {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.7rem;

  @media screen and (min-width: 690px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0;
  }

  &__brand {
    width: 180px;
    height: 50px;
    margin-bottom: 1rem;
  }
}

.media {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 210px;
  margin-top: 40px;

  @media screen and (min-width: 690px) {
    align-items: flex-end;
    margin-top: 0;
  }

  &__link {
    margin-bottom: 10px;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__logoBerg {
    width: 185px;
    height: 47px;
  }

  &__logoBP {
    width: 169px;
    height: 34px;
  }
}

.link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 680px;
  flex-wrap: wrap;
  margin: 3rem auto;

  @media screen and (min-width: 690px) {
    flex-direction: row;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--font-primary-bold);
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
    color: #979797;
    text-decoration: underline;
    cursor: pointer;
    height: 31px;
    background-color: inherit;
    border: none;
    margin: 0 10px;

    &:hover {
      opacity: 0.8;
    }
  }
}
