@keyframes slideIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.stage {
  display: flex;
  justify-content: space-around;
  margin-bottom: 6px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: 490px) {
    padding-left: 0;
    padding-right: 0;
  }

  &__item {
    display: inline;
    color: var(--color-gray-light);
  }
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -14px;
  background-color: inherit;
  width: 50px;

  &::before {
    content: '';
    display: inline-block;
    position: relative;
    top: 14px;
    right: 49px;
    width: 50px;
    height: 1px;
    background-color: var(--color-gray-light);

    @media screen and (min-width: 490px) {
      right: 62px;
      width: 78px;
    }
  }

  &::after {
    content: '';
    display: inline-block;
    position: relative;
    bottom: 68px;
    left: 49px;
    width: 50px;
    height: 1px;
    background-color: var(--color-gray-light);

    @media screen and (min-width: 490px) {
      left: 62px;
      width: 78px;
    }
  }

  &__bird {
    width: 26px;
    z-index: 1500;
    animation-duration: 1s;
    animation-name: slideIn;
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid transparent;
    margin-bottom: 5px;
    animation-duration: 1s;
    animation-name: slideIn;

    &Active {
      @extend .element__circle;

      background-color: black;
      border: 2px solid var(--color-white);
    }

    &Dot {
      background-color: var(--color-gray-light);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      z-index: 100;

      &Active {
        @extend .element__circleDot;

        background-color: var(--color-white);
      }
    }
  }

  &__text {
    text-align: center;
    width: 90px;
    height: 50px;
    font-size: var(--font-size-s);
    font-weight: var(--font-primary-bold);

    &Active {
      @extend .element__text;

      color: var(--color-white);
    }
  }
}
