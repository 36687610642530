.wrapper {
  margin-top: 12rem;
  margin-bottom: 18.5rem;
}

.map {
  display: flex;
  flex-direction: column;
  background-color: #2c2c2c;
  box-shadow: 0px 3px 12px rgba(34, 68, 90, 0.15);
  border-radius: 10px;
  max-width: 590px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 1040px) {
    max-width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__description {
    width: 100%;
    min-height: 588px;
    padding: 30px 15px 0 15px;

    @media screen and (min-width: 1040px) {
      width: 590px;
      min-height: 748px;
      padding: 40px 48px 0 48px;
    }
  }

  &__email {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: var(--color-white);
    box-shadow: 0px 1.38365px 5.53459px rgba(0, 0, 0, 0.25);

    &Icon {
      width: 25px;
      height: 21px;
    }
  }

  &__map {
    width: 100%;
    min-height: 461px;
    color: var(--color-white);
    background-image: url('../../assets/map_berg.jpg');
    background-size: cover;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media screen and (min-width: 1040px) {
      width: 730px;
      min-height: 748px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 0;
    }
  }

  &__phoneIcon {
    width: 46px;
    height: 46px;
    margin-left: 10px;
  }

  &__title {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    height: 8rem;
    text-align: center;
  }
}

.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-light);

  &Border {
    @extend .description;

    border-bottom: none;
  }

  &__mediaBox {
    display: flex;
    justify-content: space-between;
    width: 103px;
  }

  &__link {
    width: 37px;
    height: 57px;
    &:hover {
      opacity: 0.8;
    }
  }

  &__paragraph {
    font-weight: var(--font-primary-bold);
    font-size: var(--font-size-mx);
    line-height: var(--line-height-mx);

    &Decoration {
      @extend .description__paragraph;

      margin-bottom: 2.4rem;
    }

    &Margin {
      @extend .description__paragraph;

      margin-top: 2.4rem;
    }

    &Normal {
      font-weight: var(--font-primary-regular);
      font-size: var(--font-size-md);
      line-height: var(--line-height-md);
      margin-bottom: 2.4rem;

      &Margin {
        @extend .description__paragraphNormal;
        margin-bottom: 0;
        margin-top: 2.4rem;
      }
    }
  }

  &__socialIcon {
    width: 37px;
    height: 37px;
    margin-top: 2.4rem;
    margin-right: 29px;
  }

  &__qr {
    width: 0;
    height: 0;

    @media screen and (min-width: 1040px) {
      margin-top: 30px;
      width: 102px;
      height: 102px;
    }
  }

  &__phone {
    @extend .description__paragraph;

    color: var(--color-white);
    font-size: var(--font-size-mx);
    font-weight: bold;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }

    &Mail {
      @extend .description__phone;

      text-decoration: underline;
    }
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background: #2c2c2c;
  box-shadow: 0px 4.15094px 8.30189px rgba(34, 68, 90, 0.1);
  border-radius: 10px;
  max-width: 680px;
  min-height: 80px;

  @media screen and (min-width: 600px) {
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 35px;
    padding-right: 35px;
  }

  &__point {
    width: 55px;
    height: 55px;
  }
}
