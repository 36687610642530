.container {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.section {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.box {
  width: 1920px;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;

  @media screen and (min-width: 490px) {
    min-height: 100vh;
    max-height: 100%;
    overflow-y: auto;
  }
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &Fixed {
    @extend .image;

    position: fixed;
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }
}
