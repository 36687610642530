.header {
  position: relative;
  background: hsla(0, 0%, 0%, 0.9);
  border-bottom: 1px solid #424242;

  @media screen and (min-width: 490px) {
    border-bottom: none;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: 1320px;
    height: 90px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--color-white);
  }

  &__logo {
    margin-top: 5px;
    width: 60px;
    height: 60px;
  }

  &__logoMercedes {
    position: relative;
    left: -65px;
    margin-top: 5px;
    width: 180px;
    height: 50px;
  }
}

.country {
  display: none;

  @media screen and (min-width: 996px) {
    display: flex;
    align-items: center;
  }

  &__button {
    background-color: inherit;
    border: none;
    outline-style: none;
  }

  &__flag {
    width: 31px;
    height: 26px;
  }

  &__name {
    font-weight: var(--font-primary-bold);
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-left: 0.6rem;
  }
}
